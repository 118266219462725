<template>
  <transition>
    <div
      v-if="filterToggle"
    >
      <b-form
        class="border-dashed"
      >
        <b-row>
          <b-col md="9">
            <!--Customer-->
            <b-row>
              <!--Company name-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.COMPANY_NAME)"
                >
                  <b-form-input
                    v-model="filters[Customers.COMPANY_NAME]"
                    :placeholder="filterClass.getLabel(Customers.COMPANY_NAME)"
                  />
                </b-form-group>
              </b-col>
              <!--Pseudonym-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.PSEUDONYM)"
                >
                  <b-form-input
                    v-model="filters[Customers.PSEUDONYM]"
                    :placeholder="filterClass.getLabel(Customers.PSEUDONYM)"
                  />
                </b-form-group>
              </b-col>
              <!--Status-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Customers.STATUS)"
                >
                  <l-select-filter
                    v-model="filters[Customers.STATUS]"
                    :field="filterClass.fields[Customers.STATUS]"
                    :name="Customers.STATUS"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--Address-->
            <b-row>
              <!--Account Manager-->
              <b-col md="4">
                <b-form-group
                  :label="filterClass.getLabel(Customers.ACCOUNT_MANAGER)"
                >
                  <l-select-filter
                    v-model="filters[Customers.ACCOUNT_MANAGER]"
                    :is-disabled="isDisabled"
                    :field="filterClass.fields[Customers.ACCOUNT_MANAGER]"
                    :name="Customers.ACCOUNT_MANAGER"
                  />
                </b-form-group>
              </b-col>
              <!--Street-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.ADDRESS, Customers.ADDRESS_STREET)"
                >
                  <b-form-input
                    v-model="filters[Customers.ADDRESS][Customers.ADDRESS_STREET]"
                    :placeholder="filterClass.getLabel(Customers.ADDRESS, Customers.ADDRESS_STREET)"
                  />
                </b-form-group>
              </b-col>
              <!--City-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.ADDRESS, Customers.ADDRESS_CITY)"
                >
                  <b-form-input
                    v-model="filters[Customers.ADDRESS][Customers.ADDRESS_CITY]"
                    :placeholder="filterClass.getLabel(Customers.ADDRESS, Customers.ADDRESS_CITY)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--Contact-->
            <b-row>
              <!--Country-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.ADDRESS, Customers.ADDRESS_COUNTRY)"
                >
                  <l-select-filter
                    v-model="filters[Customers.ADDRESS][Customers.ADDRESS_COUNTRY]"
                    :is-disabled="isDisabled"
                    :field="filterClass.fields[Customers.ADDRESS][Customers.ADDRESS_COUNTRY]"
                    :name="Customers.ADDRESS_COUNTRY"
                  />
                </b-form-group>
              </b-col>
              <!--State-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.ADDRESS, Customers.ADDRESS_STATE)"
                >
                  <l-select-child-filter
                    v-model="filters[Customers.ADDRESS][Customers.ADDRESS_STATE]"
                    :is-disabled="isDisabled"
                    :field="filterClass.fields[Customers.ADDRESS][Customers.ADDRESS_STATE]"
                    :name="Customers.ADDRESS_STATE"
                    :parent-value="filters[Customers.ADDRESS][Customers.ADDRESS_COUNTRY]"
                    parent-no-selected-text="Please select a country"
                  />
                </b-form-group>
              </b-col>
              <!--First Name-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_FIRSTNAME)"
                >
                  <b-form-input
                    v-model="filters[Customers.CONTACT][Customers.CONTACT_FIRSTNAME]"
                    :placeholder="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_FIRSTNAME)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!--Last Name-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_LASTNAME)"
                >
                  <b-form-input
                    v-model="filters[Customers.CONTACT][Customers.CONTACT_LASTNAME]"
                    :placeholder="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_LASTNAME)"
                  />
                </b-form-group>
              </b-col>
              <!--Email-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_EMAIL)"
                >
                  <b-form-input
                    v-model="filters[Customers.CONTACT][Customers.CONTACT_EMAIL]"
                    :placeholder="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_EMAIL)"
                  />
                </b-form-group>
              </b-col>
              <!--Phone-->
              <b-col md="4">
                <b-form-group
                  :disabled="isDisabled"
                  :label="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_PHONE)"
                >
                  <b-form-input
                    v-model="filters[Customers.CONTACT][Customers.CONTACT_PHONE]"
                    :placeholder="filterClass.getLabel(Customers.CONTACT, Customers.CONTACT_PHONE)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <label class="font-weight-bolder">{{ $t('Business Transactions') }}</label>
            <b-table-simple
              v-for="field in [
                Customers.ALLOW_RENTALS,
                Customers.ALLOW_TO_RENT_FROM_SUBRENT,
                Customers.ALLOW_TO_PURCHASE_FROM_SUPPLIER,
                Customers.BALANCE_DUE,
              ]"
              :key="field"
            >
              <b-row class="checkboxGroup">
                <b-col md="10">
                  <b-thead>
                    <label
                      :for="field"
                      class="checkboxLabel"
                    >
                      {{ filterClass.getLabel(field) }}
                    </label>
                  </b-thead>
                </b-col>
                <b-col
                  md="2"
                  class="d-flex justify-content-end"
                >

                  <b-form-checkbox
                    :id="field"
                    v-model="filters[field]"
                    :disabled="isDisabled"
                    class="checkbox ml-auto"
                    value="1"
                    :unchecked-value="null"
                  />
                </b-col>
              </b-row>
            </b-table-simple>
            <label class="font-weight-bolder mt-1">{{ $t('Verification Status') }}</label>
            <b-table-simple
              v-for="field in [
                Customers.ID_SCAN_VERIFIED,
                Customers.ID_SCAN_NOT_VERIFIED,
                Customers.ACCOUNT_SETUP_INCOMPLETE,
                Customers.ID_SCAN_FAILED,
              ]"
              :key="field"
            >
              <b-row class="checkboxGroup">
                <b-col md="10">
                  <b-thead>
                    <label
                      :for="field"
                      class="checkboxLabel"
                    >
                      {{ filterClass.getLabel(field) }}
                    </label>
                  </b-thead>
                </b-col>
                <b-col
                  md="2"
                  class="d-flex justify-content-end"
                >

                  <b-form-checkbox
                    :id="field"
                    v-model="filters[field]"
                    :disabled="isDisabled"
                    class="checkbox ml-auto"
                    value="1"
                    :unchecked-value="null"
                  />
                </b-col>
              </b-row>
            </b-table-simple>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-button
              variant="success"
              @click="onFilter"
            >
              <feather-icon icon="LFilterIcon" />
              {{ $t('Filter') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <div v-else>
      <span
        v-if="$route.query.hasOwnProperty('search')"
        class="border-dashed mr-1 d-inline-block"
      >
        <b>Search:</b> <span v-html="$route.query.search" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSearchFilter()"
        />
      </span>
      <span
        v-for="selectedFilter in selectedFilters"
        v-if="selectedFilter.path.toString() !== 'is_active'"
        :key="selectedFilter.path.join('.')"
        class="border-dashed mr-1 d-inline-block"
      >
        <span v-html="selectedFilter.value" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSelectedFilter(selectedFilter.path)"
        />
      </span>
    </div>
  </transition>
</template>

<script>
import {
  BForm, BButton, BRow, BCol,
  BFormGroup, BFormInput, BFormCheckbox,
  BTableSimple, BThead,
} from 'bootstrap-vue'
import LSelectFilter from '@/views/components/filters/formComponents/LSelectFilter.vue'
import LSelectChildFilter from '@/views/components/filters/formComponents/LSelectChildFilter.vue'
// eslint-disable-next-line import/no-cycle
import Customers from '@/views/components/filters/fields/Customers'

export default {
  name: 'CustomersFilterComponent',
  components: {
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    LSelectFilter,
    BFormInput,
    LSelectChildFilter,
    BFormCheckbox,
    BTableSimple,
    BThead,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    filterToggle: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: [Array, Object],
      default: () => ({}),
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    selectedFilters: {
      type: [Array],
      default: () => ([]),
    },
  },
  data() {
    return {
      filterCustomer: {},
    }
  },
  computed: {
    Customers() {
      return Customers
    },
  },
  methods: {
    onFilter() {
      this.$emit('onFilter', this.filter)
    },
    removeSelectedFilter(path) {
      this.filterClass.removeSelectedFilter(this.filters, path)
      this.$emit('onRemoveSelectedFilter', path)
    },
    removeSearchFilter() {
      this.$emit('searchQueryClear')
    },
    onChange(event) {
      if (event) {
        console.log(event)
        // this.selected2 = "Immediate";
        this.isDisabled = true
      }
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
