<template>
  <v-select
    class="selectInput"
    placeholder="Select..."
    :value="value"
    v-bind="getProps()"
    :options="selectOptions"
    :loading="isSelectLoading"
    :disabled="isDisabled"
    @search="onSearch"
    @input="emitData"
  >
    <div slot="no-options">
      {{ getNoOptionLabel() }}
    </div>

  </v-select>
</template>
<script>
import vSelect from 'vue-select'
import { isObject } from '@core/utils/utils'

function debounce(func, timeout) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }
}

export default {
  components: {
    vSelect,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    parentValue: {
      required: true,
      default: null,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    parentNoSelectedText: {
      type: String,
      default: 'Please, select parent',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectOptions: [],
      isSelectLoading: false,
      isSearchable: false,
    }
  },
  watch: {
    parentValue(newValue, oldValue) {
      if (newValue === oldValue) return
      if (newValue === null) {
        this.emitData(null)
        this.selectOptions = []
        return
      }
      if (newValue && oldValue) {
        this.emitData(null)
      }
      this.getSelectOptions({ parentValue: this.getParentValue(newValue) })
    },
  },
  mounted() {
    if (this.field.store && this.parentValue !== null) {
      this.getSelectOptions({ parentValue: this.getParentValue(this.parentValue) })
    }
  },
  methods: {
    getProps() {
      return {
        label: 'name',
        ...(this.field.options ?? {}),
      }
    },
    getParentValue(parentValue) {
      if (isObject(parentValue)) {
        return parentValue.id
      }
      return parentValue
    },
    getSelectOptions(params) {
      this.isSelectLoading = true
      this.$store
        .dispatch(`listModule/${this.field.store}`, params)
        .then(res => {
          const body = res.data.data
          if (body && body.per_page) {
            this.isSearchable = true
          }
          this.selectOptions = this.$store.state.listModule[this.field.store]
        })
        .finally(() => {
          this.isSelectLoading = false
        })
    },
    onSearch(searchQuery, loading) {
      if (this.isSearchable && searchQuery.length) {
        loading(true)
        this.search(searchQuery, this)
      }
    },
    getNoOptionLabel() {
      if (this.parentValue) {
        return 'Sorry, no matching options.'
      }
      return this.parentNoSelectedText
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions({ name: searchQuery, parentValue: ctx.getParentValue(ctx.parentValue) })
    }, 1000),
    emitData(selectedValue) {
      this.$emit('input', selectedValue)
    },
  },
}
</script>
<style></style>
